@import 'colours';

.footer {
  background-color: $grey;
  text-align: center;
  font-size: 14px;
  color: $dark-text;
  padding: 20px 30px 30px 30px;
  clear: both;

  .footer-copyright {
    margin: 20px auto;
    max-width: 450px;
  }

  .footer-poweredby {
    font-size: 10px;
    font-weight: bold;
  }

  .poweredbySE {
    background-color: $black;
    width: 80%;
    max-width: 250px;
    border-radius: 40px;
    margin: 20px auto 0px auto;
    display: block;
    color: white;
    text-decoration: none;

    img {
      display: inline-block;
      vertical-align: middle;
      min-width: 120px;
      width: 40%;
      padding: 10px;
    }
  }
}
