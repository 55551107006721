$cream: #FFFEF7;
$orange: #D86A3F;
$pale-orange: #E6C2AC;
$dark-cream: #E8E7DB;
$blue: #97C2C0;
$brown: #91512E;

$black: #161918;
$light-grey: #D8D8D8;
$grey: #F7F7F7;
$dark-green: #202523;
$cyan: #00D6BE;

$dark-background: $black;
$light-background: white;

$dark-text: $black;
$light-text: $light-grey;
