@import 'colours';
@import 'fonts';

.health {
  background-color: $light-background;
  color: $dark-text;
  padding: 100px 0px 2px 0px;
  text-align: center;

  .title {
    @extend %font-extended-medium;
    font-size: 25px;
    padding: 0 24px;
  }

  .list {
    margin: 0 auto;
    padding: 36px 36px 0 36px;

    .item {
      margin: 0 auto 36px auto;
      font-weight: 300;
      font-size: 20px;
      max-width: 350px;
    }

    .image {
      display: block;
      max-height: 70px;
      margin: 0 auto 12px auto;
    }
  }
}