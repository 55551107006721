@import 'colours';

.header {
  text-align: center;
  position: sticky;
  top: 0;
  width: 100%;
  height: 11vh;
  z-index: 10;
  margin: 0 auto;
  transition: opacity 0.250s ease-out, background-color 0.125s ease-out, transform 0.125s ease-out;
  opacity: 0;

  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $dark-background;
    z-index: -1;
    transition: background-color 0.125s ease-out, transform 0.125s ease-out;
  }

  &.visible {
    opacity: 1;
  }

  &.over-content {
    background-color: $light-background;

    &::before {
      background-color: $light-background;
    }
  }

  &.small {
    transform: translateY(-15%);

    &::before {
      transform: scaleY(0.75);
    }
  }

  .logo {
    display: inline-block;
    max-height: 35px;
    margin: 30px auto 0 auto;
    transition: background-color 0.125s ease-out, transform 0.125s ease-out;

    &.small {
      transform: scale(0.75);
    }
  }

  .logo-black {
    margin-top: -135px;
    margin-bottom: 0;
    position: relative;
    z-index: 1;
    opacity: 0;
  }
}