body {
  margin: 0;
  min-height: 100%;
  width: 100%;
  
  font-family: "Avenir", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;

  font-size: 16px;
  line-height: 1.5;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  background-color: #161918;
  color: #D8D8D8;
}
