@import 'colours';
@import 'fonts';

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100vw;
  height: 100vh;

    .centred {
      // Vertically centre the titles block
      margin-top: -35px;
    }

    .logo {
      width: 250px;
      height: 35px;
      margin-bottom: 32px;
    }

    // .titles {}

    .title {
      @extend %font-extended-medium;
      color: white;
      margin: -12px 0;
      font-size: 32px;
      line-height: 1;
    }

    %outline-with-solid-fill {
      // Works in recent browsers, but can’t use with transparent fill
      color: $dark-text;
      text-shadow: 0 0 1px white, 0 0 1px white, 0 0 1px white, 0 0 1px white;
    }

    %outline-with-transparent-fill {
      // Works in Webkit-based browsers only, but allows a transparent fill
      color: rgba($dark-text, 0);
      -webkit-text-fill-color: rgba($dark-text, 0);
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: white;
    }

    .faded {
      position: relative;
      z-index: -1;

      // Add outline style
      @extend %outline-with-transparent-fill;      
    }

    .faded-1 {
      opacity: 0.2;
    }

    .faded-2 {
      opacity: 0.4;
    }

    .faded-3 {
      opacity: 0.6;
    }

    .faded-4 {
      opacity: 0.8;
    }
}