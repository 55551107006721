
%font-regular {
  // Avenir Book
  font-family: 'Avenir', sans-serif;
  font-weight: 300;
  font-style: normal;
}

%font-medium {
  // Avenir Black
  font-family: 'Avenir', sans-serif;
  font-weight: 500;
  font-style: normal;
}

// %font-wide-regular {
//   /* TitlingGothicFB Wide Regular */
//   font-family: titling-gothic-fb-wide, sans-serif;
//   font-weight: 300;
//   font-style: normal;
// }

// %font-wide-medium {
//   /* TitlingGothicFB Wide Medium */
//   font-family: titling-gothic-fb-wide, sans-serif;
//   font-weight: 500;
//   font-style: normal;
// }

%font-extended-regular {
  /* TitlingGothicFB Extended Regular */
  font-family: titling-gothic-fb-extended, sans-serif;
  font-weight: 300;
  font-style: normal;
}

%font-extended-medium {
  /* TitlingGothicFB Extended Medium */
  font-family: titling-gothic-fb-extended, sans-serif;
  font-weight: 500;
  font-style: normal;
}