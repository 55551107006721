@import 'colours';
@import 'fonts';

.recipe {
  background-color: $light-background;
  color: $dark-text;
  padding: 100px 0 30px 0;
  text-align: center;

  .title {
    @extend %font-extended-medium;
    font-size: 25px;
    padding: 0 24px;
  }

  .subtitle {
    @extend %font-extended-medium;
    font-size: 16px;
    padding: 0 24px;
  }

  .image {
    width: 100%;
    height: auto;
    margin: 0;
    max-width: 500px;
  }

  .ingredients,
  .method {
    margin: 0 auto;
    padding: 0;
    text-align: left;
    padding: 42px 24px 0 24px;
    max-width: 500px;
  }

  .section-title {
    font-size: 17px;
    font-weight: bold;
  }

  ul, ol {
    text-align: left;
    margin: 0;
    padding: 0;

    li {
      font-size: 14px;
    }
  }

  ul {
    list-style: none;

    li {
      line-height: 2.2;
    }
  }

  ol {
    padding-left: 14px;

    li {
      padding-left: 10px;
      // line-height: 2.2;
      padding-bottom: 10px;
    }
  }
}