@import 'colours';
@import 'fonts';

.intro {
  width: 100vw;
  height: 89vh;

  .summary {
    text-align: center;
    height: 54vh;
    padding: 24px;

    .image {
      width: auto;
      height: 100%;
      max-height: 30vh;
    }

    .title {
      @extend %font-extended-medium;
      font-size: 25px;
    }

    .subtitle {
      @extend %font-extended-regular;
      font-size: 16px;
    }
  }

  .details {
    position: relative;
    background-color: $dark-green;
    position: relative;
    padding: 36px 24px 0 24px;
    font-size: 17px;
    line-height: 1.6;
    min-height: 230px;
    height: 35vh;

    &::before {
      content: '';
      position: absolute;
      display: block;
      width: 3px;
      height: 50px;
      top: -25px;
      left: 50%;
      background-color: $cyan;
    }

    .list {
      max-width: 400px;
      margin: 0 auto;
    }

    .label {
      width: 50%;
      font-weight: bold;
      text-align: left;
      display: inline-block;
    }

    .value {
      width: 50%;
      font-weight: 300;
      text-align: right;
      display: inline-block;
    }

    .scroll-hint {
      text-align: center;
      font-weight: bold;
      font-size: 15px;
      position: absolute;
      bottom: 24px;
      width: 200px;
      left: calc( 50% - 100px);
      text-decoration: none;
      color: white;

      .scroll-icon {
        display: block;
        margin: 6px auto;
      }
    }
  }
}